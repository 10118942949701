/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './BatchesForm.module.css';
  import RadioButton from '../../components/Batches/RadioButton';
 import Counter from '../../components/Shared/Textfields/CounterShort';
import { BatchesService } from '../../services/BatchesService';
import { BatchDTO, BatchFormDTO, CreateBatchDTO, UpdateBatchDTO } from '../../dto/BatchesDTO';
 import DropdownSelector from '../../components/Shared/Textfields/DropdownSelector';
import { PublishersService } from '../../services/PublishersService';
import {BooksDTO, BooksResponseDTO, Option } from '../../dto/BooksDTO';
import { BooksService } from '../../services/BooksService';
import { SchoolsService } from '../../services/SchoolsService';
import { SchoolsDTO } from '../../dto/SchoolsDTO';
import { PublisherResponseDTO, PublishersDTO } from '../../dto/PublishersDTO';
import { showErrorToast, showSuccessToast } from '../../components/Shared/Toastify/toastHelper';
import { GenerateAccessCodeDTO } from '../../dto/DashboardDTO';
import { CircularProgress } from '@mui/material';
import ArchiveModal from '../../components/Shared/ArchiveModal/ArchiveModal';
import { UserContext } from '../../UserContext';

interface PublisherFormProps {
  mode: 'add' | 'edit' | 'view';
}


const BatchesForm: React.FC<PublisherFormProps> = ({ mode }) => {
  const {user}=useContext(UserContext)
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [batchData, setBatchData] = useState<BatchFormDTO|null>(null);
  const [selectedValue, setSelectedValue] = useState<string>("on use");


  const [publisherOptions,setPublisherOptions]=useState<{label:string,value:string}[]>([]);
  const [booksOptions, setBooksOptions] = useState<{label:string,value:string}[]>([]);
  const [schoolsOptions, setSchoolsOptions] = useState<{label:string,value:string}[]>([]);


  const [selectedPublisher, setSelectedPublisher] = useState<Option|null>(null);
  const [selectedBook, setSelectedBook] = useState<Option|null>(null);
  const [selectedSchools, setSelectedSchools] = useState<Option|null>(null);


  const [expirationDay, setExpirationDays] = useState<number | undefined>(1);
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);

  const [loadingBatch,setLoadingBatch]=useState(true)
  const [loadingPublisher,setLoadingPublisher]=useState(true)
  const [loadingBooks,setLoadingBooks]=useState(true)
  const [loadingSchools,setLoadingSchools]=useState(true)

  const [buttonLoading,setButtonLoading]=useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false); 
  const [modalMode, setModalMode] = useState<'archive' | 'delete'|'activate'>('archive');

  const handleDelete = () => {
    setModalMode('delete');
    setModalOpen(true);
  };

  const handleArchive = () => {
    setModalMode('archive');
    setModalOpen(true);
  };

  const handleActivate = () => {
    setModalMode('activate');
    setModalOpen(true);
  };

  const handleConfirm = async () => {
   
    if (modalMode === 'delete' && batchData?._id ) {
      try {
       const message= await BatchesService.deleteBatch( batchData._id);
        showSuccessToast(message);
        navigate('/batches');
      } catch (error:any) {
        showErrorToast('Error deleting batch: '+error.message);
      }
    } else if (modalMode === 'archive' && batchData?._id) {
      try {
        const message=await BatchesService.toggleArchive( batchData._id);
        showSuccessToast(message);
        navigate('/batches');
      } catch (error:any) {
        showErrorToast('Error toggling archive batch: '+error.message);
      }
    }
    else if (modalMode === 'activate' && batchData?._id) {
      try {
        const message=await BatchesService.toggleActivate( batchData._id);
        showSuccessToast(message);
        navigate('/batches');
      } catch (error:any) {
        
        showErrorToast('Error toggling activate batch: '+error.message);
      }
    }
    setModalOpen(false); 
  };

  //fetch batch
  useEffect(() => {
    const fetchBatch = async () => {
      try {
         if (id) {
          const fetchedData:BatchDTO = await BatchesService.getBatchById( id);
          setBatchData({
            _id:fetchedData._id,
            Title: fetchedData.title ?? "", 
            Publisher: fetchedData.book.publisher?.fullname|| "", 
            Book: fetchedData.book?.bookTitle ||"", 
            School: fetchedData.school?.name ||"",
            LicenceType: fetchedData.licenseType ?? "fixed", 
            NumberOfCodeNeed:'',
            CodeLength:undefined,
            CodeStart: "", 
            CodeEnd: "",
            CharacterList: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
            StartDate: fetchedData.dateCreated ?? "", 
            EndDate:undefined,
            Archived:fetchedData.archived,
            Active:fetchedData.active,
            expirationDays:fetchedData.expirationDays
          });
          if (fetchedData.accessCodes && fetchedData.accessCodes.length > 0) {
            // Get the max expiration date
            const maxExpirationDate = fetchedData.accessCodes
              .map(code => new Date(code.expirationDate))  // Convert each expirationDate to a Date object
              .reduce((maxDate, currentDate) => (currentDate > maxDate ? currentDate : maxDate), new Date(0));  // Find the latest date
            
            const formattedMaxDate = maxExpirationDate.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
            setExpiryDate(formattedMaxDate);
          }
          
          setExpirationDays(fetchedData.expirationDays);
          

          setSelectedPublisher({
            label: fetchedData.book.publisher?.fullname || "",
            value: fetchedData.book.publisher?._id || "",
          });
          
          setSelectedBook({
            label: `${fetchedData.book?.bookTitle } ${fetchedData.book?.grades.length>0?"|"+ fetchedData.book?.grades.map(grade => grade.engName).join(' - '):""} ${fetchedData.book?.category?.engName?"|"+ fetchedData.book?.category?.engName:""}`,
            value: fetchedData.book?._id || "",
          });
  
          setSelectedSchools({
            label: fetchedData.school?.name || "",
            value: fetchedData.school?._id || "",
          });

          setSelectedValue(fetchedData.licenseType)
        }else{
          showErrorToast("Error batch id not found.")
        }
      } catch (error:any) {
        showErrorToast('Error fetching batch: '+error.message)
      
      }finally{
        setLoadingBatch(false)
      }
    };

    if (id) {
      fetchBatch();
    }else{
      setBatchData({        
        CharacterList: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
      });
      setLoadingBatch(false)
    }
  }, [mode, id]);

 //fetch publishers
  useEffect(() => {
  const fetchPublishers = async () => {
    try {
      
      const response:PublisherResponseDTO = await PublishersService.getPublishers(false,1000000,1,"");
      const options = response.publishers.map((publisher: PublishersDTO) => ({
        label: publisher.fullname,
        value: publisher._id,
      }));
      setPublisherOptions(options);
      
    } catch (error:any) {
      showErrorToast('Error fetching publishers: '+error.message)

    }finally{
      setLoadingPublisher(false)
    }
  };

  fetchPublishers();
  }, []);

  //fetch books
  useEffect(() => {
    const fetchBooks = async () => {
      try {
   
        const response:BooksDTO[] = await BooksService.getBooksByPublisher(selectedPublisher?.value!);
        const options = response.map((book: BooksDTO) => ({
            label: `${book?.bookTitle } ${book?.grades.length>0?"| "+ book?.grades.map(grade => grade.engName).join(' - '):""} ${book?.category?.engName?"| "+ book?.category?.engName:""}`,
          value: book._id,
        }))
        setBooksOptions(options);
      
      } catch (error:any) {
        showErrorToast('Error fetching books: '+error.message)
      }finally{
        setLoadingBooks(false)
      }
    };
  
    fetchBooks();
  }, [selectedPublisher]);
  
  //fetch schools
  useEffect(() => {
    const fetchPublishers = async () => {
      try {
   
          const response = await SchoolsService.getSchools(false,1,1000000,"");
          const options = response.schools.map((school: SchoolsDTO) => ({
            label: `${school.name}${school.region ? ` | ${school.region}` : ''}${school.casaId ? ` | ${school.casaId}` : ''}`,
            value: school._id,
          }));
          setSchoolsOptions(options);
     
      } catch (error:any) {
        showErrorToast('Error fetching schools: '+error.message)

        
      }finally{
        setLoadingSchools(false)
      }
    };
  
    fetchPublishers();
  }, []);

  const handlePublisherChange = (selected: Option|Option[]) => {
    const selectedOption=selected as Option;
    setSelectedBook(null)
    setSelectedPublisher(selectedOption);
    setBatchData({ ...batchData, Publisher: selectedOption.value || '',Book:'' });
  };

  const handleBookChange = (selected: Option|Option[]) => {
    const selectedOption=selected as Option;
    setSelectedBook(selectedOption);
    setBatchData({ ...batchData, Book: selectedOption.value });
  };

  const handleSchoolsChange = (selected: Option|Option[]) => {
    const selectedOption=selected as Option;
    setSelectedSchools(selectedOption);
    setBatchData({ ...batchData, School: selectedOption.value });
  };

  const handleTypeChange = (selected: string) => {
    setSelectedValue(selected);
    setBatchData({ ...batchData, LicenceType: selected });
    
    if (selected === 'on use') {
        setExpirationDays(0); 
        setExpiryDate(undefined);
    } else if (selected === 'fixed') {
        setExpirationDays(undefined);
        setExpiryDate(""); 
    }
  };

  const handleNumberOfCode =(e:React.ChangeEvent<HTMLInputElement>)=>{
    const value=e.target.value
    const number=Number(value)
    if (value === "" || (!isNaN(number) && number>=0)) {
      setBatchData({ ...batchData, NumberOfCodeNeed: value });
    }  
  }

  const handleCodeLength =(e:React.ChangeEvent<HTMLInputElement>)=>{
    const value=e.target.value
    const number=Number(value)

    if (value === "" || (!isNaN(number) && number>=0)) {
      setBatchData({ ...batchData, CodeLength: value });
    }  
  }
  
  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!validateForm()){
      return
    }
    setButtonLoading(true)
    try{
      if (mode === 'add') {
        await handleAdd()
        navigate('/batches');
      } else if (mode === 'edit') {
        await handleUpdate()
        navigate(`/batches/view/${id}`);
      }
    }catch(error){
      return
    }
  };

  const handleAdd=async()=>{
    try {
      if(!batchData){
        throw new Error('Batch data is required');
      }
      
      const createBatchData: CreateBatchDTO = {
        batchData: {
          title: batchData.Title,
          book: batchData.Book!,
          school: batchData.School!,
          licenseType: selectedValue!,
          expirationDays: selectedValue === 'on use' ? expirationDay : undefined,
          accessCodes: [],
        },
        numCodes: parseInt(batchData.NumberOfCodeNeed!),  
        charList: batchData.CharacterList!,
        prefix: batchData.CodeStart!,
        suffix: batchData.CodeEnd!,
        expiryDate:selectedValue === 'fixed'? expiryDate : undefined,
        length:parseInt(batchData.CodeLength?batchData.CodeLength:"16")
      };
        await BatchesService.addBatch(createBatchData);
        showSuccessToast('Batch Created!')
    } catch (error:any) {
      showErrorToast('Error creating batch: '+error.message)
      throw error;
    }finally{
      setButtonLoading(false)
    }
    
  }

  const handleUpdate = async () => {
    try {
      if (!batchData || !selectedBook || !selectedSchools) {
        throw new Error('Batch data, book, and school are required');
      }
      const updateBatchData: UpdateBatchDTO = {
        title: batchData.Title!,
        book: selectedBook.value,  
        school: selectedSchools.value,  
        accessCodes: [], 
        expiryDate: batchData.LicenceType==='fixed' ?expiryDate:undefined,
        expirationDays: batchData.LicenceType === 'on use' ? expirationDay : undefined, // Include this for "on use"
      };

      //generate access codes
      if(batchData.NumberOfCodeNeed && batchData.CodeLength && batchData.CharacterList ){
        if(batchData.LicenceType==='fixed' && !expiryDate){
          showErrorToast('end date required for fixed type')
          return
        }
        
        const body:GenerateAccessCodeDTO={
          "charList": batchData.CharacterList,
          "numCodes": parseInt(batchData.NumberOfCodeNeed),
          "prefix": batchData.CodeStart||"",
          "suffix": batchData.CodeEnd||"",
          "length":parseInt(batchData.CodeLength),
          "expiryDate": selectedValue === 'fixed' ? expiryDate : undefined,
        }
        await BatchesService.generateAccessCode( id!, body)

      }

      await BatchesService.updateBatch( id!, updateBatchData); 
      showSuccessToast('Batch updated!')
    } catch (error:any) {
      showErrorToast('Error updating batch: '+error.message)
      throw error
    }finally{
      setButtonLoading(false)
    }
  };
  
  if(loadingBatch || loadingBooks || loadingPublisher || loadingSchools){
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'100%'}}>
        <CircularProgress/>
      </div>
    )
  }

  const validateForm = (): boolean => {
    const missingFields: string[] = [];

    // Check for missing or invalid fields
    if (!batchData?.Title?.trim()) missingFields.push('Title');
    if (!selectedPublisher) missingFields.push('Publisher');
    if (!selectedBook) missingFields.push('Book');
    if (!selectedSchools) missingFields.push('Schools');

    // For 'add' mode, check additional fields
    if (mode === 'add') {
        if (!batchData?.NumberOfCodeNeed || isNaN(parseInt(batchData.NumberOfCodeNeed.toString()))) {
          missingFields.push('Number of Codes Needed');
        }

        if (!batchData?.CharacterList?.trim()) missingFields.push('Character List');

          // Check expiration date and day
        if (selectedValue === 'on use' && (expirationDay === undefined || expirationDay < 0)) {
          missingFields.push('Expiration Day');
        }

        if (selectedValue === 'fixed' && !expiryDate) {
          missingFields.push('Expiry Date');
        }

    }

    // If any fields are missing, show an error toast and return false
    if (missingFields.length > 0) {
        showErrorToast(`The following fields are missing: ${missingFields.join(', ')}`);
        return false;
    }

    return true;
};


  return (
    <div className={styles.background}>
      <div className={styles.Title}>Batch Information</div>
      <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.row}>
        <div className={styles.field}>
          <label>Title</label>
          <input
            value={batchData?.Title}
            onChange={(e) => setBatchData({ ...batchData, Title: e.target.value })}
            className={`${styles.fullWidth}`}
            disabled={mode === 'view'}
          />
        </div>  
      </div>
        <hr className={styles.hr}/>

        <div className={styles.row}>
          <DropdownSelector label="Publisher" options={publisherOptions} selectedOptions={selectedPublisher} onChange={handlePublisherChange} disabled={mode==='view'} />
        </div>
        <div className={styles.row}>
          <DropdownSelector label="Book" options={booksOptions}  selectedOptions={selectedBook} onChange={handleBookChange} disabled={selectedPublisher===null || mode==="view"} placeholder={selectedPublisher===null?"Select a publisher first":'Search...'}/>
        </div>
        <hr className={styles.hr}/>

        <div className={styles.row}>
          <DropdownSelector label="Institution" options={schoolsOptions} selectedOptions={selectedSchools} onChange={handleSchoolsChange} disabled={mode==='view'}/>
        </div>        
        <div className={styles.Title}>License Type</div>
        <div className={styles.row}>
          <div className={styles.field}>
            <RadioButton selectedValue={selectedValue} onChange={handleTypeChange} disbaled={mode==='view'}/>
          </div>
        </div>
 

        <div className={styles.row}>
          {selectedValue === 'on use' && (
          <div className={styles.field}>
              <label>Days</label>
              <Counter 
                  initialCount={0} 
                  counter={expirationDay || 0} 
                  onChange={(value) => {
                      setExpirationDays(value);
                      setBatchData({ ...batchData, Days: value });
                  }}
                  disabled={mode === 'view'} 
              />
        </div>
        )}
          {selectedValue === 'fixed' && (
            <div className={styles.field}>
                <label>Expiry Date</label>
                <input
                    type="date"
                    value={expiryDate || ""}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    className={styles.textField}
                    disabled={mode === 'view'}
                />
            </div>
          )}
        </div>

        <div className={styles.Title}>Access Code Generation</div>
       
        <div className={styles.row}>


          <div className={styles.field}>
            <label>Number Of Codes Needed:</label>
            <input
              type="number"
              value={batchData?.NumberOfCodeNeed}
              onChange={handleNumberOfCode}
              className={styles.textField}
              disabled={mode === 'view'}
            />
          </div>
          <div className={styles.field}>
            <label>Code Length (Character)</label>
            <input
              type="number"
              value={batchData?.CodeLength}
              onChange={handleCodeLength}
              className={styles.textField}
              disabled={mode === 'view'}
            />
          </div>
        </div>
     
         <div className={styles.row}>
         <div className={styles.field}>
            <label>Code Start</label>
            <input
              type="text"
              value={batchData?.CodeStart}
              onChange={(e) => setBatchData({ ...batchData,CodeStart: e.target.value })}
               disabled={mode === 'view'}
               className={styles.textField}

            />
          </div>
          <div className={styles.field}>
            <label>Code End</label>
            <input
              type="text"
              value={batchData?.CodeEnd}
              onChange={(e) => setBatchData({ ...batchData,CodeEnd: e.target.value })}
               disabled={mode === 'view'}
               className={styles.textField}

            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.field}>
            <label>Character List</label>
            <input
              value={batchData?.CharacterList}
              onChange={(e) => setBatchData({ ...batchData,CharacterList: e.target.value })}
              className={`${styles.fullWidth} `}
  
              disabled={mode === 'view'}
            />
          </div>
        </div>
        
        {mode !== 'view' && (
          <div className={styles.buttonContainer}>
            {mode==='edit' && user?.role==='admin'?
            <>
              <button className={styles.DeleteButton} type="button" onClick={handleDelete}>DELETE</button>
              <button className={styles.ArchiveButton} type="button" onClick={handleActivate}>{batchData?.Active?"DEACTIVATE":'ACTIVATE'}</button>
              <button className={styles.ArchiveButton} type="button" onClick={handleArchive}>{batchData?.Archived?'UNARCHIVE':'ARCHIVE'}</button>
            </>
            :<></>}
            <button className={styles.AddButton} type="submit">{!buttonLoading?mode === 'add' ? 'ADD' : 'UPDATE':<CircularProgress style={{color:'white'}}/>}</button>
          </div>
        )}
      </form>
      <ArchiveModal
            setIsOpen={setModalOpen}

        isOpen={modalOpen}
        onConfirm={handleConfirm}
        onCancel={() => setModalOpen(false)}
        header={modalMode === 'archive' ? `${batchData?.Archived?'Unarchive':'Archive'} Batch` : modalMode==='activate'? `${batchData?.Active?'Deactivate':'Activate'} Batch`:  'Delete Batch'}
        parag={modalMode === 'archive' ? `Are you sure you want to ${batchData?.Archived?'unArchive':'archive'} this batch?` : modalMode==='activate'? `Are you sure you want to ${batchData?.Active?'deactivate':'activate'} this batch?`: 'Are you sure you want to delete this batch?'}
        buttonText={modalMode === 'archive' ? batchData?.Archived?'unarchive':'archive' : modalMode==='activate'? batchData?.Active?'deactivate':'activate'  : 'Delete'}
        mode={modalMode}
      />
    </div>
  );
}

export default BatchesForm;
