import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, LinearScale, CategoryScale, ChartOptions } from 'chart.js';
import styles from './AccessCodeUsageSchool.module.css';
import { ReactComponent as ArrowIcon } from '../../images/Reports/ArrowDownIcon.svg';
import { ReactComponent as ArrowIconBold } from '../../images/Reports/ArrowBold.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/Reports/ArrowRightIcon.svg';
import { ReactComponent as AddIcon } from '../../images/Reports/AddIcon.svg';
import { ReactComponent as RemoveIcon } from '../../images/Reports/RemoveIcon.svg';
import { BooksDTO, BooksResponseDTO } from '../../dto/BooksDTO';
import { BooksService } from '../../services/BooksService';
import { BatchDTO, BatchResponseDTO } from '../../dto/BatchesDTO';
import { BatchesService } from '../../services/BatchesService';
import { ReportsService } from '../../services/ReportsService';
import { ReactComponent as SmallArrow } from '../../images/Reports/SmallIcon.svg';
import getYearsFrom2024 from '../../utils/dateUtils';
import { CircularProgress } from '@mui/material';
import { showErrorToast } from '../Shared/Toastify/toastHelper';
import { ReactComponent as DetailsIcon } from '../../images/Reports/DetailsIcon.svg';
import ExportReportButton from '../Shared/ExportButton/ExportReportButton';

Chart.register(LineElement, PointElement, LinearScale, CategoryScale);
interface ResponseItem {
  _id: {
    year: number;
    month: number;
  };
  usedCodesCount: number;
}

interface BatchDropdownDTO {
  _id:string;
  name: string;
  isOpen: boolean;
  searchTerm: string;
}

interface BookDropdownDTO {
  _id: string;
  name: string;
  searchTerm: string;
  isOpen: boolean;
}
interface GraphData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    fill:boolean;
  }[];
}
const AccessCodeUsageSchool: React.FC = () => {
  const years = useMemo<string[]>(() => getYearsFrom2024(), []);
 
  const [showDropdown, setShowDropdown] = useState(false);
  const [batchDropdowns, setBatchDropdowns] = useState<BatchDropdownDTO[]>([
    { _id:'',name: '', isOpen: false, searchTerm: '' },
  ]);
  const [allBatches, setAllBatches] = useState<BatchDTO[]>([]);
  const [allBooks, setAllBooks] = useState<BookDropdownDTO[]>([]);

  const [selectedBook, setSelectedBook] = useState<{value:string,label:string}>({value:'',label:''});
  const [selectedYear, setSelectedYear] = useState<string>(years[0]||'2024');
  const [isArrowBold, setIsArrowBold] = useState(true);
  const [bookDropdown, setBookDropdown] = useState(false);
  const [yearDropdown,setYearDropdown]=useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const bookDropdownRef = useRef<HTMLDivElement | null>(null);
  const batchDropdownRefs = useRef<(HTMLDivElement | null)[]>([]);
  const yearDropdownRef=useRef<HTMLDivElement|null>(null)

  // State for graph data
  const [graphData, setGraphData] = useState<GraphData>({
    labels: [],
    datasets: [],
  });

  const [loadingGraph,setLoadingGraph]=useState(false)
  const [batchesLoading,setBatchesLoading]=useState(true)
  const [booksLoading,setBooksLoading]=useState(true)

  // Fetch books 
  useEffect(() => {   
    const fetchData = async () => {
      try {
        setBooksLoading(true)
        const bookResponse: BooksResponseDTO = await BooksService.getBooks( 1000000, 1, false, true, '');
        if (bookResponse && Array.isArray(bookResponse.books)) {
          const mappedBooks: BookDropdownDTO[] = bookResponse.books.map((book: BooksDTO) => ({
            _id: book._id,
            name: book.bookTitle,
            searchTerm: '',
            isOpen: false,
          }));
          setAllBooks(mappedBooks);
        }
      } catch (error:any) {
        showErrorToast('Error fetching books: '+error.message)

        
      }finally{
        setBooksLoading(false)
      }
    };
    fetchData();
  }, []);

  //fetch batches
  useEffect(()=>{
    const fetchData=async()=>{
        try {
  
          if(!selectedBook.value){
            return 
          }
          setBatchesLoading(true)

          const batchResponse  = await BatchesService.getBatchesByBookId(selectedBook.value);
          if (batchResponse && Array.isArray(batchResponse)) {
            setAllBatches(batchResponse);
          }
        } catch (error:any) {
          showErrorToast('Error fetching batches: '+error.message)

        }finally{
          setBatchesLoading(false)
        }
    }

    fetchData()
  },[selectedBook])

  //fetch graph data
  useEffect(() => {
    const fetchGraphData = async () => {
      try {
          
  
          if (!selectedBook.value) {
              return;
          }
          const allBatchesHaveIds = batchDropdowns.every(batch => batch._id);
          if (!allBatchesHaveIds) {
            return;
          }
        setLoadingGraph(true)

          const selectedBatchIds = batchDropdowns.map(x => x._id);
          const responses = await Promise.all(selectedBatchIds.map(batchId => 
            ReportsService.getYearlyAccessCodePerBatch( batchId, selectedBook.value, selectedYear)
          ))
          
          const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          const datasets = responses.map((response, index) => {
            const data = Array(12).fill(0); 
  
            response.result.forEach((item: ResponseItem) => {
              const month = item._id.month - 1; 
              data[month] = item.usedCodesCount;
            });
  
            return {
              label: batchDropdowns[index].name, 
              data: data,
              borderColor: `rgba(${index * 50}, ${192 - index * 30}, ${192}, 1)`,
              fill: false,
            };
          });
          setGraphData({ labels: monthLabels, datasets });
  
      } catch (error:any) {
        showErrorToast('Error fetching graph data: '+error.message)

          
      }finally{
        setLoadingGraph(false)
      }
  };
    fetchGraphData();
  }, [selectedBook,JSON.stringify(batchDropdowns.filter((p) => p._id).map((p) => p._id)),selectedYear]);

  const handleBatchSelection = (batchName: string, index: number,id:string) => {
    selectBatch(batchName, index);
    setBatchDropdowns((prev) =>
      prev.map((batch, i) => (i === index ? { ...batch, isOpen: false,_id:id } : batch)) 
    );
  
  };
  
  const handleBookSelection = (bookName: string,id:string) => {
    setSelectedBook({label:bookName,value:id});
    setBookDropdown(false)
    setBatchDropdowns([{_id:'',name: '', isOpen: false, searchTerm: '' }])
    setGraphData({  
      labels: [],
      datasets: [],
    })
  
  };

  const handleSearchChange = (index: number, event: React.ChangeEvent<HTMLInputElement>, isBatch: boolean) => {
    const value = event.target.value;
    if (isBatch) {
      const updatedBatches = batchDropdowns.map((batch, i) => (i === index ? { ...batch, searchTerm: value} : batch));
      setBatchDropdowns(updatedBatches);
    } else {
      const updatedBooks = allBooks.map((book, i) => (i === index ? { ...book, searchTerm: value } : book));
      setAllBooks(updatedBooks);
    }
  };

  const handleYearClick = () => {
    setYearDropdown(!yearDropdown);
  };
  
  const selectYear = (year: string) => {
    setSelectedYear(year);
    setYearDropdown(false);
    setLoadingGraph(true)
  };

  const filteredBatches = (index: number) => {
    return allBatches.filter((batch) =>
      batch.title.toLowerCase().includes(batchDropdowns[index].searchTerm.toLowerCase())
    );
  };

  const filteredBooks = () => {
    return allBooks.filter((book) => book.name.toLowerCase().includes(selectedBook.label.toLowerCase()));
  };

  const toggleDropdown = (index: number) => {
    setBatchDropdowns((prev) =>
      prev.map((batch, i) => (i === index ? { ...batch, isOpen: !batch.isOpen, searchTerm: '' } : batch))
    );
  };

  const toggleBookDropdown=()=>{
    setBookDropdown(!bookDropdown);
    setSelectedBook({value:'',label:''});
    setBatchDropdowns([{_id:'',name: '', isOpen: false, searchTerm: '' }])
  }
  
  const selectBatch = (batchName: string, index: number) => {
    setBatchDropdowns((prev) =>
      prev.map((batch, i) => (i === index ? { ...batch, name: batchName, searchTerm: batchName } : batch))
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click was outside the main dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
        setBookDropdown(false);
        setBatchDropdowns((prev) =>
          prev.map(batch => ({ ...batch, isOpen: false })) // Close all batch dropdowns
        );
      }

      // Check if click was outside each batch dropdown
      batchDropdownRefs.current.forEach((ref, index) => {
        if (ref && !ref.contains(event.target as Node)) {
          setBatchDropdowns((prev) => prev.map((batch, i) => i === index ? { ...batch, isOpen: false } : batch));
        }
      });

      // Check if click was outside the book dropdown
      if (bookDropdownRef.current && !bookDropdownRef.current.contains(event.target as Node)) {
        setBookDropdown(false);
      }

      if(yearDropdownRef.current && !yearDropdownRef.current.contains(event.target as Node)){
        setYearDropdown(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const options: ChartOptions<'line'> = {
    plugins: {
      tooltip: {
        enabled: true, // Ensure tooltips are enabled
        mode: 'index', // Tooltip mode
        intersect: false, // Tooltip should show on all items at that index
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw as number;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
    },
    interaction: {
      mode: 'nearest', // Change to nearest to ensure tooltips show on hover
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: '#e0e0e0',
        },
        ticks: {
          stepSize: 1,
          callback: (value) => Number(value).toFixed(0),
        },
      },
    },
  };
  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => setShowDropdown(!showDropdown)}>
        Access Code Usage Per Book
        <div className={styles.iconContainer}>
        <ExportReportButton exportType='books' bookId={selectedBook.value} batchIds={batchDropdowns.map(batch=>batch._id)} year={selectedYear}/>
        
          {isArrowBold ? (
            <ArrowIconBold className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
          ) : (
            <ArrowRightIcon className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
          )}
        </div>
      </div>

      {showDropdown && (
        <>
          <hr className={styles.line} />
          <div className={styles.dropdownContainer}>
            <div className={styles.dropdown}  ref={bookDropdownRef}>
              <div className={styles.dropdownTitle}>Book</div>
              <div className={styles.dropdownHeader} onClick={toggleBookDropdown} >
                <input
                  type="text"
                  placeholder="Search Book..."
                  onChange={(e) => setSelectedBook({label:e.target.value,value:''})}
                  className={styles.searchInput}
                  value={selectedBook.label}
                />
                <ArrowIcon className={`${styles.arrow} ${bookDropdown ? styles.arrowDown : ''}`} />
              </div>
              {bookDropdown && (
                <div className={styles.dropdownList}  >
                  {!booksLoading?
                    filteredBooks().length ===0 ?
                    <div className={styles.dropdownItem}>No data found</div>
                    : filteredBooks().map((book, i) => (
                      <div
                        key={i}
                        className={styles.dropdownItem}
                        onClick={() => {
                          handleBookSelection(book.name,book._id)
                        }}
                      >
                        {book.name}
                      </div>
                    )):<div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}><CircularProgress/></div>}
                </div>
              )}
            </div>

            {batchDropdowns.map((batch, index) => (
              <div key={index}>
                <div className={styles.icons}>
                  <div className={styles.dropdownTitle}>Batch {index + 1}</div>
                  {index === batchDropdowns.length - 1 && (
                    <AddIcon
                      className={styles.icon}
                      onClick={() =>
                        setBatchDropdowns([...batchDropdowns, { name: `Batch ${batchDropdowns.length + 1}`, isOpen: false, searchTerm: '',_id:''}])
                      }
                    />
                  )}
                  {index > 0 && index === batchDropdowns.length - 1 && (
                    <RemoveIcon
                      className={styles.icon}
                      onClick={() => setBatchDropdowns(batchDropdowns.filter((_, i) => i !== index))}
                    />
                  )}
                </div>
                <div className={styles.dropdown} ref={el => batchDropdownRefs.current[index] = el}>
                  <div className={styles.dropdownHeader} onClick={() => toggleDropdown(index)}>
                    <input
                      type="text"
                      placeholder={!selectedBook.value?'Please select a book':'Search Batch...'}
                      value={batch.searchTerm}
                      onChange={(e) => handleSearchChange(index, e, true)}
                      className={styles.searchInput}
                      disabled={!selectedBook.value}
                    />
                    <ArrowIcon className={styles.icon} />
                  </div>
                  {batch.isOpen && (
                   !batchesLoading?
                    <div className={styles.dropdownList}>
                      
                      {filteredBatches(index).length!==0?
                        filteredBatches(index).map((batch, i) => (
                          <div
                            key={i}
                            className={styles.dropdownItem}
                            onClick={() => handleBatchSelection(batch.title, index,batch._id)}
                          >
                            {batch.title.length>0? batch.title : 'No data found'}
                          </div>
                      )):<>No data found</>}
                    </div>
                    :
                    <div className={styles.dropdownList}>
                      <div className={styles.dropdownItem}>
                        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}><CircularProgress/></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.chartControls}>
              <div className={styles.accessCodeLabel}>Access codes used</div>
              <div className={styles.yearDropdown} ref={yearDropdownRef}>
                <div className={styles.yearHeader} onClick={handleYearClick}>
                  {selectedYear}
                  <SmallArrow className={`${styles.smallArrow} ${yearDropdown ? styles.arrowUp : ''}`} />
                </div>
                {yearDropdown && (
                  <div className={styles.yearList}>
                    {years.map((year, i) => (
                      <div key={i} className={styles.yearItem} onClick={() => selectYear(year)}>
                        {year}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          <div className={styles.chart}>

           {!loadingGraph?
            <Line 
              data={graphData} 
              options={{ ...options, maintainAspectRatio: false }} 
              height={250}
            />
          :<CircularProgress/>}
          </div>
          <div className={styles.legend}>
                {batchDropdowns.map((batch, index) => (
                  <div key={index} className={styles.legendItem}>
                    <div className={styles.colorCircle} style={{ backgroundColor: `rgba(${index * 50}, ${192 - index * 30}, ${192}, 1)` }} />
                    <span>{batch.name}</span>
                  </div>
                ))}
          </div>
        </> 
      )}
    </div>
  );
};
export default AccessCodeUsageSchool;
