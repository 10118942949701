import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, LinearScale, CategoryScale, ChartOptions } from 'chart.js';
import styles from './AccessCodeUsageSchool.module.css';
import { ReactComponent as ArrowIcon } from '../../images/Reports/ArrowDownIcon.svg';
import { ReactComponent as ArrowIconBold } from '../../images/Reports/ArrowBold.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/Reports/ArrowRightIcon.svg';
import { ReactComponent as AddIcon } from '../../images/Reports/AddIcon.svg';
import { ReactComponent as RemoveIcon } from '../../images/Reports/RemoveIcon.svg';
import { ReactComponent as SmallArrow } from '../../images/Reports/SmallIcon.svg';
import { ReactComponent as DetailsIcon } from '../../images/Reports/DetailsIcon.svg';

import { BatchDTO, BatchResponseDTO } from '../../dto/BatchesDTO';
import { showErrorToast } from '../Shared/Toastify/toastHelper';
import { BatchesService } from '../../services/BatchesService';
import { ReportsService } from '../../services/ReportsService';
import getYearsFrom2024 from '../../utils/dateUtils';
import { CircularProgress } from '@mui/material';
import ExportReportButton from '../Shared/ExportButton/ExportReportButton';

Chart.register(LineElement, PointElement, LinearScale, CategoryScale);

interface BatchDropdown {
  id:string;
  name: string;
  isOpen: boolean;
  searchTerm:string;
}

const AccessCodeUsageBatch: React.FC = () => {

  const years = useMemo<string[]>(() => getYearsFrom2024(), []);



  const [showDropdown, setShowDropdown] = useState(false);
  const [allBatches,setAllBatches]=useState<BatchDTO[]>([])
  const [batchDropdowns, setBatchDropdowns] = useState<BatchDropdown[]>([
    { name: '', isOpen: false,searchTerm:'',id:'' },
  ]);
  const [isArrowBold, setIsArrowBold] = useState(true);
  const [yearDropdown, setYearDropdown] = useState(false);
  const [selectedYear, setSelectedYear] = useState(years[0] || '2024');

  const [graphData,setGraphData]=useState<any>(null)
  const inputDropdownRef=useRef<(HTMLDivElement|null)[]>([])

  const [loading,setLoading]=useState(false)
  const [dropdownLoading,setDropdownLoading]=useState(true)
  //close dropdwon
  useEffect(()=>{
    const handleClickOutside=(event:MouseEvent)=>{
      inputDropdownRef.current.forEach((ref,index)=>{
        if(ref && !ref.contains(event.target as Node)){
          setBatchDropdowns((prev)=>(
            prev.map((dropdown,i)=>(i===index?{...dropdown,isOpen:false}:dropdown))
          ))
        }
      })

    }
    document.addEventListener('mousedown',handleClickOutside);
    return ()=>{
      document.addEventListener('mousedown',handleClickOutside);
    }
  },[])


  //fetch batches
  useEffect(()=>{
    const fetchBatches=async()=>{
      try {

        const response:BatchResponseDTO=await BatchesService.getBatches(1,1000000,true,false,'');
        if(response?.batches && Array.isArray(response.batches)){
          setAllBatches(response.batches)
        }
      } catch (error:any) {
          
          showErrorToast('Error fetching batches: '+error.message)
      }finally {
        setDropdownLoading(false)
      }

    }
    
    fetchBatches()
  },[])

  
  const colors = ['#00726B', '#BE1E2D', '#FFA500', '#800080'];
  //fetch graph data
  useEffect(() => {
    const fetchGraphData = async () => {
      try {
        
        
        setLoading(true)

        const datasets = await Promise.all(
          batchDropdowns
            .filter((batch) => batch.id)
            .map(async (batch, index) => {
              const response = await ReportsService.getAccessCodeUsagePerBatch(
                batch.id, selectedYear
              );
  
              if (!response || !Array.isArray(response.result)) {
                console.error(`Unexpected response for batch ${batch.name}:`, response);
                throw new Error(`Invalid data format for publisher: ${batch.name}`);
              }
  
              // Initialize an array of 12 months with default value 0
              const monthlyData = Array(12).fill(0);
  
              // Populate monthlyData with actual values from the response
              response.result.forEach((item: { _id: { month: number }; usedCodesCount: number }) => {
                const monthIndex = item._id.month - 1; // Adjust for 0-based index
                monthlyData[monthIndex] = item.usedCodesCount;
              });
  
              return {
                label: batch.name,
                data: monthlyData,
                borderColor: colors[index % colors.length],
                fill: false,
              };
            })
        );
  
        setGraphData({
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets,
        });
      } catch (error:any) {
        
        showErrorToast('Error fetching graph data: '+error.message);
      }finally{
        setLoading(false)
      }
    };
  
    fetchGraphData();
  }, [JSON.stringify(batchDropdowns.filter((p) => p.id).map((p) => p.id)), selectedYear]);

  const options: ChartOptions<'line'> = {
    plugins: {
      tooltip: {
        enabled: true, // Ensure tooltips are enabled
        mode: 'index', // Tooltip mode
        intersect: false, // Tooltip should show on all items at that index
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw as number;
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
    },
    interaction: {
      mode: 'nearest', // Change to nearest to ensure tooltips show on hover
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
          color: '#e0e0e0',
        },
        ticks: {
          stepSize: 1,
          callback: (value) => Number(value).toFixed(0),
        },
      },
    },
  };

  const addBatch = (index: number) => {
    const newBatch = { name: `Batch ${batchDropdowns.length + 1}`, isOpen: false,searchTerm:'',id:'' };
    const updatedDropdowns = [...batchDropdowns];
    updatedDropdowns.splice(index + 1, 0, newBatch);
    setBatchDropdowns(updatedDropdowns);
  };

  const removeBatch = (index: number) => {
    const updatedDropdowns = batchDropdowns.filter((_, i) => i !== index);
    setBatchDropdowns(updatedDropdowns);
    setLoading(true)
  };

  const toggleDropdown = (index: number) => {
    const updatedDropdowns = batchDropdowns.map((batch, i) => i === index ? { ...batch, isOpen: !batch.isOpen,searchTerm:''} : batch);
    setBatchDropdowns(updatedDropdowns);
  };

  const selectBatch = (batchName: string, index: number,id:string) => {
    const updatedDropdowns = [...batchDropdowns];
    updatedDropdowns[index].name = batchName;
    updatedDropdowns[index].isOpen = false;
    updatedDropdowns[index].searchTerm = batchName;
    updatedDropdowns[index].id = id;
    setBatchDropdowns(updatedDropdowns);
  };

  const handleHeaderClick = () => {
    setShowDropdown(!showDropdown);
    setIsArrowBold(!isArrowBold);
  };

  const handleYearClick = () => {
    setYearDropdown(!yearDropdown);
  };

  const selectYear = (year: string) => {
    setSelectedYear(year);
    setYearDropdown(false);
    setLoading(true)
  };

  const handleSearchChange=(value:string,index:number)=>{
    const updatedDropdowns = [...batchDropdowns];
    updatedDropdowns[index].searchTerm=value;
    setBatchDropdowns(updatedDropdowns);
  }

  const filteredData=(data:BatchDTO[],searchTerm:string):BatchDTO[]=>{
    return data.filter(x=>x.title.toLowerCase().includes(searchTerm))
  }

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={handleHeaderClick}>
        Access Code Usage Per Batch
        <div className={styles.iconContainer}>
        <ExportReportButton exportType='batch' batchIds={batchDropdowns.map(batch=>batch.id)} year={selectedYear}/>

          {isArrowBold ? (
            <ArrowIconBold className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
          ) : (
            <ArrowRightIcon className={`${styles.arrow} ${showDropdown ? styles.arrowUp : ''}`} />
          )}
        </div>
      </div>


      {showDropdown && (
        <>
        <hr className={styles.line} />
          <div className={styles.dropdownContainer}>
            {batchDropdowns.map((batch, index) => (
              <div key={index}>
                <div className={styles.icons}>
                  <div className={styles.dropdownTitle}>Batch {index + 1}</div>
                  {index === batchDropdowns.length - 1 ? (
                    <AddIcon className={styles.icon} onClick={() => addBatch(index)} />
                  ) : null}
                  {index > 0 && index === batchDropdowns.length - 1 ? (
                    <RemoveIcon className={styles.icon} onClick={() => removeBatch(index)} />
                  ) : null}
                </div>
                <div className={styles.dropdown} ref={(element)=>inputDropdownRef.current[index]=element}>
                  <div className={styles.dropdownHeader} onClick={() => toggleDropdown(index)}>
                    <input type="text" 
                      value={batch.searchTerm} 
                      onChange={(e)=>handleSearchChange(e.target.value,index)}
                      placeholder='Search Batch...'
                      className={styles.inputSearch}
                    />
                    <ArrowIcon className={styles.icon} />
                  </div>
                  {batch.isOpen && (
                    <div className={styles.dropdownList}>
                      {!dropdownLoading?
                        allBatches
                          .filter(x => x.title.toLowerCase().includes(batch.searchTerm.toLowerCase()))
                          .length === 0 ? (
                            <div className={styles.dropdownItem}>No data found</div>
                          ) : (
                            allBatches
                              .filter(x => x.title.toLowerCase().includes(batch.searchTerm.toLowerCase()))
                              .map((batch, i) => (
                                <div key={i} className={styles.dropdownItem} onClick={() => selectBatch(batch.title, index, batch._id)}>
                                  {batch.title}
                                </div>
                              ))
                          ):<div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center' ,padding:'8px'}}><CircularProgress/></div>
                      }
                    </div>
                  )}  
                </div>
              </div>
            ))}
          </div>

          <div className={styles.chartControls}>
            <div className={styles.accessCodeLabel}>Access codes used</div>
            <div className={styles.yearDropdown}>
              <div className={styles.yearHeader} onClick={handleYearClick}>
                {selectedYear}
                <SmallArrow className={`${styles.smallArrow} ${yearDropdown ? styles.arrowUp : ''}`} />
              </div>
              {yearDropdown && (
                <div className={styles.yearList}>
                  {years.map((year, i) => (
                    <div key={i} className={styles.yearItem} onClick={() => selectYear(year)}>
                      {year}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={styles.chart}>
            {!loading?
              <Line data={graphData} options={{ ...options, maintainAspectRatio: false }} height={250} />
              :<CircularProgress/>
            }
          </div>

          <div className={styles.legend}>
            {batchDropdowns.map((batch, index) => (
              <div key={index} className={styles.legendItem}>
                <div className={styles.colorCircle} style={{ backgroundColor: colors[index % colors.length] }} />
                <span>{batch.name}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AccessCodeUsageBatch;
