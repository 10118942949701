import { FetchAuth } from "../utils/FetchAuthUtil";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

export class SchoolsService {
    public static getSchools = async (
        archived: boolean,
        page: number,
        limit: number,
        searchTerm: string,
        sortColumn?:string|null,
        sortDirection?:string
    ): Promise<any> => {
        try {
            const params = new URLSearchParams({
                ...(page && {page: String(page)}),
                ...(limit && {limit: String(limit)}),
                ...(archived !== null && { archived: String(archived) }),
                ...(searchTerm && { name:String(searchTerm) })
            });
            if (sortColumn && sortDirection) {
                params.append('sort', `${sortColumn}:${sortDirection}`);
            }
            const resp = await FetchAuth(
                `${endpoint}/school/admin/getAllSchools?${params.toString()}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const data = await resp.json();
            return data;
        } catch (error) {
            throw error;
        }
    };

    // Create a new school
    public static createSchool = async (schoolData: any): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/createSchool`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(schoolData),
            });

        

            const data = await resp.json();
            return data;
        } catch (error) {
            throw error;
        }
    };

    // Get school details by ID
    public static getSchoolById = async (_id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/getSchool/${_id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

        

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };

    // Update school details
    public static updateSchool = async (_id: string, schoolData: any): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/updateSchool/${_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(schoolData),
            });
 

            const data = await resp.json();
            return data;
        } catch (error) {
             throw error;
        }
    };

    // Delete a school
    public static deleteSchool = async ( _id: string): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/deleteSchool/${_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            

            const data = await resp.json();
            return data.message;
        } catch (error) {
             throw error;
        }
    };

    // Toggle school archive status
    public static toggleArchive = async ( _id: string | null): Promise<any> => {
        try {
            const resp = await FetchAuth(`${endpoint}/school/toggleArchiveStatus/${_id}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
            });

         

            const data = await resp.json();
            return data.message;
        } catch (error) {
             throw error;
        }
    };

    // Get file in specified format (excel, csv, pdf)
    public static getFile = async (format: "excel" | "csv" | "pdf"): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/exportSchools?format=${format}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

         

            const blob = await resp.blob();
            return blob;
        } catch (error) {
             throw error;
        }
    };
    public static getTopFile = async (format: "excel" | "csv",bookId:string,schoolId:string,year:string): Promise<Blob> => {
        try {
            const resp = await FetchAuth(`${endpoint}/admin/analytics/${schoolId}/${bookId}/yearly/export?year=${year}&fileType=${format}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

         

            const blob = await resp.blob();
            return blob;
        } catch (error) {
             throw error;
        }
    };
}
