import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

import { PublishersService } from '../../../services/PublishersService';
import { SchoolsService } from '../../../services/SchoolsService';

import { BooksService } from '../../../services/BooksService';

import { ReactComponent as DetailsIcon } from '../../../images/Reports/DetailsIcon.svg';
import { BatchesService } from '../../../services/BatchesService';
import { showErrorToast } from '../Toastify/toastHelper';

interface ExportButtonProps {
  exportType:"schools"|"batch"|"books"|"publisher"|"topBooks";
  bookId?:string;
  schoolIds?:string[];
  batchIds?:string[];
  publisherIds?:string[];
  publisherId?:string;
  year?:string;
  categoryId?:string;
  type?:string;
}

const ExportButtonContainer = styled(Button)({
  position:'relative'
});

const DropDown = styled(Box)<{ open: boolean }>(({ open }) => ({
  position: 'absolute',
  width:'inherit',
  top: '120%',
  right: '0',
  backgroundColor: '#fff',
  border: '1px solid #E5E5E5',
  borderRadius: '10px',
  zIndex: 1,
  display: open ? 'block' : 'none', 
}));
const DropDownItem =styled(Box)({
  textAlign: 'left',
  fontSize:14,
  color:'#212121',
  padding: '8px 16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f2f2f2',
  },
  '&:first-of-type': {
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  '&:last-of-type"': {
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
})

const ExportReportButton: React.FC<ExportButtonProps> = ({exportType,bookId,schoolIds,publisherIds,year,batchIds,publisherId,categoryId,type}) => {
  const [open, setOpen] = useState(false);
console.log("clicked")
  const toggleDropdown = (e:any) => {
    setOpen(!open);
    e.stopPropagation();
  };
  const handleDownloadFile = async (format: 'excel' | 'csv') => {

    let blob;
    const link = document.createElement('a');
    let fileName = ''; 
  
    try{
      if (exportType === 'publisher') { 
        let number=1
        if(publisherIds?.length!=0){
          for (const id of publisherIds!) {
          fileName ='AccessCodeUsagePerPublisher_'+number
              number++;
            blob=await PublishersService.getTopFile(format, id!, year!);
            if (!blob) {
              console.error('blob is empty');
              return;
            }
          
            
            const url = window.URL.createObjectURL(blob);
            link.href = url;
          
            
            link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
          
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }        
        }
      } else if (exportType === 'schools') {
        let number=1
        for (const schoolId of schoolIds!) {
        fileName ='AccessCodeUsagePerInstitution_'+number
            number++;
          blob=await SchoolsService.getTopFile(format, bookId!, schoolId, year!);
          if (!blob) {
            console.error('blob is empty');
            return;
          }
        
          
          const url = window.URL.createObjectURL(blob);
          link.href = url;
        
          
          link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
        
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }        
      }else if(exportType==='books'){
        let number=1
        for (const batchId of batchIds!) {
        fileName ='AccessCodeUsagePerBook_'+number
            number++;
          blob=await BooksService.getTopFile(format, bookId!, batchId, year!);
          if (!blob) {
            console.error('blob is empty');
            return;
          }
        
          
          const url = window.URL.createObjectURL(blob);
          link.href = url;
        
          
          link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
        
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      }
      else if(exportType==='batch'){
        let number=1
        for (const id of batchIds!) {
        fileName ='AccessCodeUsagePerBatch_'+number
            number++;
          blob=await BatchesService.getTopFile(format, id!, year!);
          if (!blob) {
            console.error('blob is empty');
            return;
          }
        
          
          const url = window.URL.createObjectURL(blob);
          link.href = url;
        
          
          link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
        
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }        
      }
      else if(exportType==='topBooks'){
        
        fileName ='TopDownloadedBooks'
            console.log("here")
          blob=await BooksService.getTopDownloadedFile(format,type!,categoryId,publisherId);
          if (!blob) {
            console.error('blob is empty');
            return;
          }
        
          
          const url = window.URL.createObjectURL(blob);
          link.href = url;
        
          
          link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
        
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
      }        
      
    }catch(error:any){
      showErrorToast(error.message+', please make sure you have all the selected options')
    }


    
      
    // if (!blob) {
    //   console.error('blob is empty');
    //   return;
    // }
  
    
    // const url = window.URL.createObjectURL(blob);
    // link.href = url;
  
    
    // link.setAttribute('download', `${fileName}.${format === 'excel' ? 'xlsx' : format}`);
  
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    // window.URL.revokeObjectURL(url);
  };
  


  return (
    <ExportButtonContainer  onClick={toggleDropdown}>
        <DetailsIcon/>

      <DropDown open={open}>
          <DropDownItem onClick={()=>handleDownloadFile("excel")}>
            Excel
          </DropDownItem>
          <DropDownItem onClick={()=>handleDownloadFile("csv")}>
            CSV
          </DropDownItem>
      </DropDown>
    </ExportButtonContainer>
  );
};

export default ExportReportButton;
